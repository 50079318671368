// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export default {
  sentry: {
    url: "https://6f37ba1f1ecf47aa8d414fc298f92f81@sentry.otvs.tv/567",
    release: "c08765d46a3203368402fdbd1d5a332427a98f72" || "local",
    environment: "preprod" || "local",
  },
};

const DEVELOPMENT_API_URLS = {
  API_GATEWAY: "https://api-gateway.api-services-int.ci.k8s.tvint.qa/graphql",
  APP_DISTRIBUTION: "https://app-distribution.api-services-int.ci.k8s.tvint.qa",
  APP_INVENTORY: "https://app-inventory.api-services-int.ci.k8s.tvint.qa",
  AUTH_BAKER: "https://auth-baker.api-services-int.ci.k8s.tvint.qa",
  CONTENT_MGMT: "https://content-mgmt.api-services-int.ci.k8s.tvint.qa/admin",
  CONTENT_SUITE_API:
    "https://content-suite-api.api-services-int.ci.k8s.tvint.qa",
  DEVICE_MGMT: "https://device-mgmt.api-services-int.ci.k8s.tvint.qa",
  ELASTIC_SEARCH: "https://search-engine.api-services-int.ci.k8s.tvint.qa",
  JIRA: "https://bugs.corp.vewd.com",
  MESSAGE_CENTER: "https://message-center.api-services-int.ci.k8s.tvint.qa",
  MODERATION: "https://moderation.api-services-int.ci.k8s.tvint.qa",
  PROMOTIONS: "https://promotions.api-services-int.ci.k8s.tvint.qa",
  SNAP_API: "https://snapapi.api-services-int.ci.k8s.tvint.qa/api/v1",
  SU_MANIFEST_INSTRUCTIONS:
    "https://api-services-production.s3-us-west-2.amazonaws.com/docs/Vewd+SU+User+Guide.pdf",
  USER_CATALOGUE: "https://user-catalogue.api-services-int.ci.k8s.tvint.qa",
};

const PRODUCTION_API_URLS = {
  API_GATEWAY: "https://api-gateway.tvint.qa/graphql",
  APP_DISTRIBUTION: "https://app-distribution.tvint.qa",
  APP_INVENTORY: "https://app-inventory.tvint.qa",
  AUTH_BAKER: "https://auth-baker.tvint.qa",
  CONTENT_MGMT: "https://content-mgmt.tvint.qa/admin",
  CONTENT_SUITE_API: "https://content-suite-api.tvint.qa",
  DEVICE_MGMT: "https://device-mgmt.tvint.qa",
  ELASTIC_SEARCH: "https://search-engine.tvint.qa",
  JIRA: "https://bugs-clone.vewd.com",
  MESSAGE_CENTER: "https://message-center.tvint.qa",
  MODERATION: "https://moderation.tvint.qa",
  PROMOTIONS: "https://promotions.tvint.qa",
  SNAP_API: "https://snapapi.tvint.qa/api/v1",
  SU_MANIFEST_INSTRUCTIONS: "https://api-services-production.s3-us-west-2.amazonaws.com/docs/Vewd+SU+User+Guide.pdf",
  USER_CATALOGUE: "https://user-catalogue.tvint.qa",
};

export const API_URLS =
  process.env.NODE_ENV === "production"
    ? PRODUCTION_API_URLS
    : DEVELOPMENT_API_URLS;

const DEVELOPMENT_DYNAMIC_FEATURES = {
  DEVICE_COMMANDS: "true",
};

const PRODUCTION_DYNAMIC_FEATURES = {
  DEVICE_COMMANDS: "false",
};

export const DYNAMIC_FEATURES =
  process.env.NODE_ENV === "production"
    ? PRODUCTION_DYNAMIC_FEATURES
    : DEVELOPMENT_DYNAMIC_FEATURES;
